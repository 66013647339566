import "slick-carousel";
(function ($) {
  "use strict";
  $(function () {
    //большой верхний слайдер на главной странице
    $(".carousel-main").slick({
      infinite: true,
      dots: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      fade: true,
      swipeToSlide: true,
      prevArrow:
        '<svg class="slick-prev" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M36 10.2859C36 9.5384 35.3941 8.93247 34.6466 8.93247H2.16499C1.41753 8.93247 0.811588 9.5384 0.811588 10.2859C0.811588 11.0333 1.41753 11.6393 2.16499 11.6393H34.6466C35.3941 11.6393 36 11.0333 36 10.2859Z" fill="white"/>\n' +
        '<path d="M11.2424 2.3104C11.7709 1.78186 11.7709 0.924937 11.2424 0.396402C10.7139 -0.132134 9.85693 -0.132134 9.32839 0.396402L0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424C0.924938 11.7709 1.78186 11.7709 2.3104 11.2424L11.2424 2.3104Z" fill="white"/>\n' +
        '<path d="M2.31041 9.32836C1.78187 8.79982 0.924942 8.79985 0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424L9.32839 20.1743C9.85693 20.7029 10.7139 20.7029 11.2424 20.1743C11.7709 19.6458 11.7709 18.7889 11.2424 18.2603L2.31041 9.32836Z" fill="white"/>\n' +
        '</svg>',
      nextArrow:
        '<svg class="slick-next" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M0 10.2859C0 9.5384 0.605938 8.93247 1.3534 8.93247H33.835C34.5825 8.93247 35.1884 9.5384 35.1884 10.2859C35.1884 11.0333 34.5825 11.6393 33.835 11.6393H1.3534C0.605939 11.6393 0 11.0333 0 10.2859Z" fill="white"/>\n' +
        '<path d="M24.7576 2.3104C24.2291 1.78186 24.2291 0.924937 24.7576 0.396402C25.2861 -0.132134 26.1431 -0.132134 26.6716 0.396402L35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424C35.0751 11.7709 34.2181 11.7709 33.6896 11.2424L24.7576 2.3104Z" fill="white"/>\n' +
        '<path d="M33.6896 9.32836C34.2181 8.79982 35.0751 8.79985 35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424L26.6716 20.1743C26.1431 20.7029 25.2861 20.7029 24.7576 20.1743C24.2291 19.6458 24.2291 18.7889 24.7576 18.2603L33.6896 9.32836Z" fill="white"/>\n' +
        '</svg>',
    });
    //слайдер "наши бренды" на главной странице
    $(".carousel-brands").slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      fade: true,
      asNavFor: '.brands__items',
      prevArrow:
        '<svg class="slick-prev" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M36 10.2859C36 9.5384 35.3941 8.93247 34.6466 8.93247H2.16499C1.41753 8.93247 0.811588 9.5384 0.811588 10.2859C0.811588 11.0333 1.41753 11.6393 2.16499 11.6393H34.6466C35.3941 11.6393 36 11.0333 36 10.2859Z" fill="white"/>\n' +
        '<path d="M11.2424 2.3104C11.7709 1.78186 11.7709 0.924937 11.2424 0.396402C10.7139 -0.132134 9.85693 -0.132134 9.32839 0.396402L0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424C0.924938 11.7709 1.78186 11.7709 2.3104 11.2424L11.2424 2.3104Z" fill="white"/>\n' +
        '<path d="M2.31041 9.32836C1.78187 8.79982 0.924942 8.79985 0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424L9.32839 20.1743C9.85693 20.7029 10.7139 20.7029 11.2424 20.1743C11.7709 19.6458 11.7709 18.7889 11.2424 18.2603L2.31041 9.32836Z" fill="white"/>\n' +
        '</svg>',
      nextArrow:
        '<svg class="slick-next" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M0 10.2859C0 9.5384 0.605938 8.93247 1.3534 8.93247H33.835C34.5825 8.93247 35.1884 9.5384 35.1884 10.2859C35.1884 11.0333 34.5825 11.6393 33.835 11.6393H1.3534C0.605939 11.6393 0 11.0333 0 10.2859Z" fill="white"/>\n' +
        '<path d="M24.7576 2.3104C24.2291 1.78186 24.2291 0.924937 24.7576 0.396402C25.2861 -0.132134 26.1431 -0.132134 26.6716 0.396402L35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424C35.0751 11.7709 34.2181 11.7709 33.6896 11.2424L24.7576 2.3104Z" fill="white"/>\n' +
        '<path d="M33.6896 9.32836C34.2181 8.79982 35.0751 8.79985 35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424L26.6716 20.1743C26.1431 20.7029 25.2861 20.7029 24.7576 20.1743C24.2291 19.6458 24.2291 18.7889 24.7576 18.2603L33.6896 9.32836Z" fill="white"/>\n' +
        '</svg>',
    });

    $(".brands__items").slick({
      dots: false,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      focusOnSelect: true,
      asNavFor: '.carousel-brands',
      responsive: [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1.5,
            centerMode: true,
            centerPadding: '70px'
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 3
          }
        }
      ]
    });

    $(".js-slick-sh3sc3").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      swipeToSlide: true,
      prevArrow:
        '<svg class="slick-prev" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M36 10.2859C36 9.5384 35.3941 8.93247 34.6466 8.93247H2.16499C1.41753 8.93247 0.811588 9.5384 0.811588 10.2859C0.811588 11.0333 1.41753 11.6393 2.16499 11.6393H34.6466C35.3941 11.6393 36 11.0333 36 10.2859Z" fill="#333333"/>\n' +
        '<path d="M11.2424 2.3104C11.7709 1.78186 11.7709 0.924937 11.2424 0.396402C10.7139 -0.132134 9.85693 -0.132134 9.32839 0.396402L0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424C0.924938 11.7709 1.78186 11.7709 2.3104 11.2424L11.2424 2.3104Z" fill="#333333"/>\n' +
        '<path d="M2.31041 9.32836C1.78187 8.79982 0.924942 8.79985 0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424L9.32839 20.1743C9.85693 20.7029 10.7139 20.7029 11.2424 20.1743C11.7709 19.6458 11.7709 18.7889 11.2424 18.2603L2.31041 9.32836Z" fill="#333333"/>\n' +
        '</svg>',
      nextArrow:
        '<svg class="slick-next" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M0 10.2859C0 9.5384 0.605938 8.93247 1.3534 8.93247H33.835C34.5825 8.93247 35.1884 9.5384 35.1884 10.2859C35.1884 11.0333 34.5825 11.6393 33.835 11.6393H1.3534C0.605939 11.6393 0 11.0333 0 10.2859Z" fill="#333333"/>\n' +
        '<path d="M24.7576 2.3104C24.2291 1.78186 24.2291 0.924937 24.7576 0.396402C25.2861 -0.132134 26.1431 -0.132134 26.6716 0.396402L35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424C35.0751 11.7709 34.2181 11.7709 33.6896 11.2424L24.7576 2.3104Z" fill="#333333"/>\n' +
        '<path d="M33.6896 9.32836C34.2181 8.79982 35.0751 8.79985 35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424L26.6716 20.1743C26.1431 20.7029 25.2861 20.7029 24.7576 20.1743C24.2291 19.6458 24.2291 18.7889 24.7576 18.2603L33.6896 9.32836Z" fill="#333333"/>\n' +
        '</svg>',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
/*
    $(".news__carousel").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      swipeToSlide: true,
      prevArrow:
        '<svg class="slick-prev" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M36 10.2859C36 9.5384 35.3941 8.93247 34.6466 8.93247H2.16499C1.41753 8.93247 0.811588 9.5384 0.811588 10.2859C0.811588 11.0333 1.41753 11.6393 2.16499 11.6393H34.6466C35.3941 11.6393 36 11.0333 36 10.2859Z" fill="#333333"/>\n' +
        '<path d="M11.2424 2.3104C11.7709 1.78186 11.7709 0.924937 11.2424 0.396402C10.7139 -0.132134 9.85693 -0.132134 9.32839 0.396402L0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424C0.924938 11.7709 1.78186 11.7709 2.3104 11.2424L11.2424 2.3104Z" fill="#333333"/>\n' +
        '<path d="M2.31041 9.32836C1.78187 8.79982 0.924942 8.79985 0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424L9.32839 20.1743C9.85693 20.7029 10.7139 20.7029 11.2424 20.1743C11.7709 19.6458 11.7709 18.7889 11.2424 18.2603L2.31041 9.32836Z" fill="#333333"/>\n' +
        '</svg>',
      nextArrow:
        '<svg class="slick-next" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M0 10.2859C0 9.5384 0.605938 8.93247 1.3534 8.93247H33.835C34.5825 8.93247 35.1884 9.5384 35.1884 10.2859C35.1884 11.0333 34.5825 11.6393 33.835 11.6393H1.3534C0.605939 11.6393 0 11.0333 0 10.2859Z" fill="#333333"/>\n' +
        '<path d="M24.7576 2.3104C24.2291 1.78186 24.2291 0.924937 24.7576 0.396402C25.2861 -0.132134 26.1431 -0.132134 26.6716 0.396402L35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424C35.0751 11.7709 34.2181 11.7709 33.6896 11.2424L24.7576 2.3104Z" fill="#333333"/>\n' +
        '<path d="M33.6896 9.32836C34.2181 8.79982 35.0751 8.79985 35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424L26.6716 20.1743C26.1431 20.7029 25.2861 20.7029 24.7576 20.1743C24.2291 19.6458 24.2291 18.7889 24.7576 18.2603L33.6896 9.32836Z" fill="#333333"/>\n' +
        '</svg>',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
*/

    $(".production__slider").slick({
      infinite: true,
      dots: true,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });

    $('.slider-product__for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      asNavFor: '.slider-product__nav',
      prevArrow: '<svg class="slick-prev" width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M28 8.00012C28 7.41876 27.5287 6.94747 26.9474 6.94747H1.68388C1.10252 6.94747 0.631235 7.41876 0.631235 8.00012C0.631235 8.58148 1.10252 9.05276 1.68388 9.05276H26.9474C27.5287 9.05276 28 8.58148 28 8.00012Z" fill="#333333"/>\n' +
        '<path d="M8.74408 1.79698C9.15516 1.38589 9.15516 0.719396 8.74408 0.308313C8.333 -0.102771 7.6665 -0.102771 7.25541 0.308313L0.308315 7.25541C-0.102769 7.6665 -0.102772 8.33299 0.308312 8.74408C0.719395 9.15516 1.38589 9.15516 1.79698 8.74408L8.74408 1.79698Z" fill="#333333"/>\n' +
        '<path d="M1.79698 7.25539C1.3859 6.84431 0.719398 6.84433 0.308315 7.25541C-0.102769 7.6665 -0.102772 8.33299 0.308312 8.74408L7.25542 15.6912C7.6665 16.1022 8.333 16.1022 8.74408 15.6912C9.15516 15.2801 9.15516 14.6136 8.74408 14.2025L1.79698 7.25539Z" fill="#333333"/>\n' +
        '</svg>',
      nextArrow: '<svg class="slick-next" width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M0 8.00012C0 7.41876 0.471285 6.94747 1.05264 6.94747H26.3161C26.8975 6.94747 27.3688 7.41876 27.3688 8.00012C27.3688 8.58148 26.8975 9.05276 26.3161 9.05276H1.05265C0.471286 9.05276 0 8.58148 0 8.00012Z" fill="#333333"/>\n' +
        '<path d="M19.2559 1.79698C18.8448 1.38589 18.8448 0.719396 19.2559 0.308313C19.667 -0.102771 20.3335 -0.102771 20.7446 0.308313L27.6917 7.25541C28.1028 7.6665 28.1028 8.33299 27.6917 8.74408C27.2806 9.15516 26.6141 9.15516 26.203 8.74408L19.2559 1.79698Z" fill="#333333"/>\n' +
        '<path d="M26.203 7.25539C26.6141 6.84431 27.2806 6.84433 27.6917 7.25541C28.1028 7.6665 28.1028 8.33299 27.6917 8.74408L20.7446 15.6912C20.3335 16.1022 19.667 16.1022 19.2559 15.6912C18.8448 15.2801 18.8448 14.6136 19.2559 14.2025L26.203 7.25539Z" fill="#333333"/>\n' +
        '</svg>',
      responsive: [
        {
          breakpoint: 640,
          settings: {
            dots: true,
            arrows: false
          }
        },
      ]
    });

    $('.slider-product__nav').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.slider-product__for',
      focusOnSelect: true,
      arrows: false
    });

    // slider in news pages
    $('.carousel-news').slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      prevArrow: "<svg class='arrows arrows--prev' width='42' height='42' viewBox='0 0 477.175 477.175'><path d='M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z'/></svg>",
      nextArrow: "<svg class='arrows arrows--next' width='42' height='42' viewBox='0 0 477.175 477.175'><path d='M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z'/></svg>",
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    // slider in blog pages
    $(".carousel-popular__slider").slick({
      infinite: !0,
      dots: !1,
      arrows: !0,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: !0,
      autoplaySpeed: 5e3,
      autoplayHoverPause: !0,
      swipeToSlide: !0,
      prevArrow: '<svg class="slick-prev" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M36 10.2859C36 9.5384 35.3941 8.93247 34.6466 8.93247H2.16499C1.41753 8.93247 0.811588 9.5384 0.811588 10.2859C0.811588 11.0333 1.41753 11.6393 2.16499 11.6393H34.6466C35.3941 11.6393 36 11.0333 36 10.2859Z" fill="#333333"/>\n<path d="M11.2424 2.3104C11.7709 1.78186 11.7709 0.924937 11.2424 0.396402C10.7139 -0.132134 9.85693 -0.132134 9.32839 0.396402L0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424C0.924938 11.7709 1.78186 11.7709 2.3104 11.2424L11.2424 2.3104Z" fill="#333333"/>\n<path d="M2.31041 9.32836C1.78187 8.79982 0.924942 8.79985 0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424L9.32839 20.1743C9.85693 20.7029 10.7139 20.7029 11.2424 20.1743C11.7709 19.6458 11.7709 18.7889 11.2424 18.2603L2.31041 9.32836Z" fill="#333333"/>\n</svg>',
      nextArrow: '<svg class="slick-next" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M0 10.2859C0 9.5384 0.605938 8.93247 1.3534 8.93247H33.835C34.5825 8.93247 35.1884 9.5384 35.1884 10.2859C35.1884 11.0333 34.5825 11.6393 33.835 11.6393H1.3534C0.605939 11.6393 0 11.0333 0 10.2859Z" fill="#333333"/>\n<path d="M24.7576 2.3104C24.2291 1.78186 24.2291 0.924937 24.7576 0.396402C25.2861 -0.132134 26.1431 -0.132134 26.6716 0.396402L35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424C35.0751 11.7709 34.2181 11.7709 33.6896 11.2424L24.7576 2.3104Z" fill="#333333"/>\n<path d="M33.6896 9.32836C34.2181 8.79982 35.0751 8.79985 35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424L26.6716 20.1743C26.1431 20.7029 25.2861 20.7029 24.7576 20.1743C24.2291 19.6458 24.2291 18.7889 24.7576 18.2603L33.6896 9.32836Z" fill="#333333"/>\n</svg>',
      responsive: [
        {breakpoint: 1300, settings: {arrows: !1}},
        {breakpoint: 1100, settings: {slidesToShow: 3}},
        {breakpoint: 850, settings: {slidesToShow: 2}},
        {breakpoint: 640, settings: {slidesToShow: 1}}
      ]
    });

    $('.history-page__images__slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: "<div class='arrows arrows--prev'><i class='icon-angle-left'></i></div>",
      nextArrow: "<div class='arrows arrows--next'><i class='icon-angle-right'></i></div>",
    });
    
    $(".js-carousel-excursions").slick({
      infinite: true,
      dots: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      swipeToSlide: true,
      prevArrow:
        '<svg class="slick-prev" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M36 10.2859C36 9.5384 35.3941 8.93247 34.6466 8.93247H2.16499C1.41753 8.93247 0.811588 9.5384 0.811588 10.2859C0.811588 11.0333 1.41753 11.6393 2.16499 11.6393H34.6466C35.3941 11.6393 36 11.0333 36 10.2859Z" fill="white"/>\n' +
        '<path d="M11.2424 2.3104C11.7709 1.78186 11.7709 0.924937 11.2424 0.396402C10.7139 -0.132134 9.85693 -0.132134 9.32839 0.396402L0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424C0.924938 11.7709 1.78186 11.7709 2.3104 11.2424L11.2424 2.3104Z" fill="white"/>\n' +
        '<path d="M2.31041 9.32836C1.78187 8.79982 0.924942 8.79985 0.396404 9.32839C-0.13213 9.85692 -0.132133 10.7139 0.3964 11.2424L9.32839 20.1743C9.85693 20.7029 10.7139 20.7029 11.2424 20.1743C11.7709 19.6458 11.7709 18.7889 11.2424 18.2603L2.31041 9.32836Z" fill="white"/>\n' +
        '</svg>',
      nextArrow:
        '<svg class="slick-next" width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M0 10.2859C0 9.5384 0.605938 8.93247 1.3534 8.93247H33.835C34.5825 8.93247 35.1884 9.5384 35.1884 10.2859C35.1884 11.0333 34.5825 11.6393 33.835 11.6393H1.3534C0.605939 11.6393 0 11.0333 0 10.2859Z" fill="white"/>\n' +
        '<path d="M24.7576 2.3104C24.2291 1.78186 24.2291 0.924937 24.7576 0.396402C25.2861 -0.132134 26.1431 -0.132134 26.6716 0.396402L35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424C35.0751 11.7709 34.2181 11.7709 33.6896 11.2424L24.7576 2.3104Z" fill="white"/>\n' +
        '<path d="M33.6896 9.32836C34.2181 8.79982 35.0751 8.79985 35.6036 9.32839C36.1321 9.85692 36.1321 10.7139 35.6036 11.2424L26.6716 20.1743C26.1431 20.7029 25.2861 20.7029 24.7576 20.1743C24.2291 19.6458 24.2291 18.7889 24.7576 18.2603L33.6896 9.32836Z" fill="white"/>\n' +
        '</svg>',
    });

  });
})(jQuery);
