import "slick-carousel";
(function ($) {
  "use strict";
  $(function () {

    $(".menu-top__link_search").on("click", function () {
      $(".search").removeClass("hide");
      $(".menu-top").addClass("hide");
    });

    $(".search__close").on("click", function (e) {
      e.preventDefault();
      $(".search").addClass("hide");
      $(".menu-top").removeClass("hide");
    });

  });
})(jQuery);
