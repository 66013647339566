import "@fancyapps/fancybox";
import "@fortawesome/fontawesome-free/js/all.js";
import "./foundation.js";
import "./fontLoad.js";
import "./carousels.js";
import "./handleInputNumber.js";
import "./stickyFooter.js";
import "./search.js";
import "./counter.js";
import "./jquery.maskedinput.min.js";
import "./phone.js";
import "./dropdown.js";

import "../scss/style.scss";

global.$ = $;

(function ($) {
  "use strict";
  $(function () {
    // your code here
  });
})(jQuery);
