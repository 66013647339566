(function ($) {
  'use strict';
  $(function () {

    $('.header__menu').on('click', function () {
      $('.dropdown').removeClass('hide');
      $('.header').addClass('hide');
    });

    $('.dropdown__close').on('click', function() {
      $('.dropdown').addClass('hide');
      $('.header').removeClass('hide');
    });

  });
})(jQuery);
