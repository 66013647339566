(function ($) {
  "use strict";
  $(function () {
    $(document).ready(function () {
      var footerHeight = 0,
        footerTop = 0,
        $footer = $(".footer");

      positionFooter();

      function positionFooter() {
        footerHeight = $footer.outerHeight();
        footerTop = $(window).scrollTop() + $(window).height() - footerHeight + "px";

        if ($(document.body).height() + footerHeight < $(window).height()) {
          $footer
            .css({
              position: "absolute",
            })
            .animate({
              top: footerTop,
            });
        } else {
          $footer.prop("style", "");
        }
      }

      $(window).scroll(positionFooter).resize(positionFooter);
    });
  });
})(jQuery);
