(function ($) {
  "use strict";
  $(function () {

    $(document).on('click', '.prod__minus', function() {
      let input = $(this).closest('.prod__price-block').find('.prod__input');
      let count = parseInt(input.val()) - 1;
      count = count < 1 ? 1 : count;
      input.val(count);
      input.change();
      return false;
    });

    $(document).on('click', '.prod__plus', function() {
      let input = $(this).closest('.prod__price-block').find('.prod__input');
      input.val(parseInt(input.val()) + 1);
      input.change();
      return false;
    });

    $('.product-info__minus').click(function () {
      let input = $('.product-info__input');
      let count = parseInt(input.val()) - 1;
      count = count < 1 ? 1 : count;
      input.val(count);
      input.change();
      return false;
    });

    $('.product-info__plus').click(function () {
      let input = $('.product-info__input');
      input.val(parseInt(input.val()) + 1);
      input.change();
      return false;
    });

  });
})(jQuery);
